var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',_vm._l((_vm.iconsAction),function(iconAction,i){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.canAccess('UI_DEPLOY:LOG_RESET') && iconAction === 'Retry'
      && (_vm.tableItem.status === 'Failed' || _vm.tableItem.status === 'Error')
      || _vm.canAccess('UI_DEPLOY:LOG_RESET') && iconAction === 'DisabledRetry'
      && _vm.tableItem.status !== 'Failed' && _vm.tableItem.status !== 'Error'
      || _vm.canAccess('UI_DEPLOY:LOG_RESET') && iconAction === 'MarkTaskAsFail'
      && _vm.tableItem.status === 'Not responding'
      || _vm.canAccess('UI_DEPLOY:LOG_RESET') && iconAction === 'MarkTaskAsSuccess'
      && _vm.tableItem.status === 'Not responding'
      || _vm.canAccess('UI_DEPLOY:LOG_RESET') && iconAction === 'DisabledMarkTaskAsFail'
      && _vm.tableItem.status !== 'Not responding'
      || _vm.canAccess('UI_DEPLOY:LOG_RESET') && iconAction === 'DisabledMarkTaskAsSuccess'
      && _vm.tableItem.status !== 'Not responding'),expression:"canAccess('UI_DEPLOY:LOG_RESET') && iconAction === 'Retry'\n      && (tableItem.status === 'Failed' || tableItem.status === 'Error')\n      || canAccess('UI_DEPLOY:LOG_RESET') && iconAction === 'DisabledRetry'\n      && tableItem.status !== 'Failed' && tableItem.status !== 'Error'\n      || canAccess('UI_DEPLOY:LOG_RESET') && iconAction === 'MarkTaskAsFail'\n      && tableItem.status === 'Not responding'\n      || canAccess('UI_DEPLOY:LOG_RESET') && iconAction === 'MarkTaskAsSuccess'\n      && tableItem.status === 'Not responding'\n      || canAccess('UI_DEPLOY:LOG_RESET') && iconAction === 'DisabledMarkTaskAsFail'\n      && tableItem.status !== 'Not responding'\n      || canAccess('UI_DEPLOY:LOG_RESET') && iconAction === 'DisabledMarkTaskAsSuccess'\n      && tableItem.status !== 'Not responding'"}],key:i,staticClass:"inline"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-img',_vm._g(_vm._b({attrs:{"id":`iiotDeployDetailsTableActionsResetAndFeedback${iconAction}Image_${_vm.index}`,"src":`/img/${iconAction}.svg`,"max-height":"40px","max-width":"40px"},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.clickAction(iconAction)}}},'v-img',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t(`defaultTable.${iconAction}`)))])])],1)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }